@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Black.ttf');
    font-weight: 900;
    font-display: swap;
}

*{
    margin:0;
    padding: 0;
}
body{
    font-family: var(--fonts) !important;
}

:root{
    --primary-color: #fa6141;
    --secondary-color: #4e4b4b; 
    --light-gray: #d8e0e7;
    --fonts : 'Poppins', sans-serif;
    /* 80b4c1 */
}
.text-poppins{
    font-family: var(--fonts) !important;

}
.text-zk-primary{
    color: var(--primary-color) !important;
}
.text-zk-primary-link{
    color: var(--primary-color) !important;
    text-decoration: none;
}
.text-zk-primary-link:hover{
    color: var(--primary-color) !important;
}
.text-dark-link-hover:hover {
    color: var(--primary-color) !important;
}
.text-zk-secondary{
    color: var(--secondary-color);
}

.bg-zk-primary{
    background-color: var(--primary-color);
}
.lh-zk{
    line-height: 1.6;
}
.bg-page{
    background: url('../images/bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* position: relative; */
}
.cart-sidebar{
    overflow: scroll;
    height: 70vh;
    max-height: 70vh;
}
.PhoneInputInput{
    border: 0 !important;
}
.PhoneInputInput:focus-visible{
    outline: 0 ;
    border: 0 !important;
}

.bg-zk-secondary{
    background-color: var(--secondary-color);
}

.btn-zk-primary{
    background-color: var(--primary-color);
    color: #fff;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}
.btn-zk-white{
    background-color: #fff;
    color: #000;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}
.btn-zk-secondary{
    background-color: var(--secondary-color);
    color: #fff;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}

.btn-zk-primary:hover{
    background-color: #f05e3e;
    color: #fff;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}

.btn-zk-white:hover{
    background-color: #fff;
    color: #000;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}

.btn-zk-white:active{
    background-color: #fff !important; 
    color: #000;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}

.btn-zk-secondary:hover{
    background-color: var(--secondary-color);
    color: #fff;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}

.btn-zk-primary:active{
    background-color: #f05e3e !important;
    color: #fff;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}

.btn-zk-white:active{
    background-color: #fff;
    color: #000;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}

.btn-zk-secondary:active{
    background-color: var(--secondary-color);
    color: #fff;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}

.slick-slide-image{
    width:15em;
}

.slick-slide {
    text-align: -webkit-center;
}

.border-zk-primary2{
    border: 2px solid var(--primary-color);
}

.home-save-money-section{
    width: 80px;
}

#website-nav {
    padding: 0rem;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-card-dropdown .dropdown-toggle::after {
    display: none !important; 
}
.header-card-dropdown .dropdown-menu{
    border: 0;
    border-radius: 0;
    box-shadow: -2px 0px 4px 0px rgb(0 0 0 / 38%);
    padding: 0;
    margin: 0;
    margin-top: 6px;
    width: 300px;
    right: 0;
    left: auto;
    top: 100%;
    font-size: 0.9rem;
    font-weight: 500;
    background-color: #ddd;
    font-family: var(--fonts) !important;
    /* transition: all 1s ease-in-out; */
}
.spacer-15{
    height: 15px;
}

.error-meesage-formik{
    color: red;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: var(--fonts) !important;
}

.StripeElement {
    background: #fff;
    border: 1px solid #c9c9c9;
    display: block;
    max-width: 100%;
    padding: 15px 14px;
}

#website-nav .nav-link{
    color: #000;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 500 !important;
    margin-right: 12px;
    font-family: var(--fonts) !important;
}
#website-nav .navbar-toggler{
    border: 0;
    background-color: #ffffff;
    border-radius: 7px;
    
}
.form-control:focus{
    box-shadow: none;
}
.form-select:focus{
    box-shadow: none;
}

.btn-promotion{
    color: var(--primary-color);
}
.btn-promotion:hover{
    color: var(--primary-color);
}
.btn-promotion:active{
    color: var(--primary-color) !important;
}


.contact-page-bg{
    background: var(--secondary-color);
    /* background: url('../images/contact/contact_bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    /* border-radius: 150px 0 150px 150px; */
}

.navbar-nav .nav-link.active{
    color: var(--primary-color) !important;
    font-weight: 600;
}

#website-nav .navbar-toggler-icon{
    width:1.8rem !important;
    height: 1.8rem !important;
}
.offcanvas.offcanvas-start{
    width: 70% !important;
}
#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .offcanvas{
    background-color: var(--primary-color);
    color: #fff;
    padding: 0rem;
}
.logo-img{
    height: 42px !important;
}

.home-section-container{
    padding-top: 100px
}
.coupon-icon{
    position: absolute;
    top: 0;
    right: 0;
    
}
.coupon-icon img{
    /* rotate */
    margin-top: -50px;
    margin-right: -40px;
    transform: rotate(-30deg);
    width: 120px;
    /* box-shadow: 10px 10px 10px 10px #fff; */
}

.container-zk{
    max-width: 92%;
    padding-right: 4%;
    padding-left: 4%;
    margin-right: auto;
    margin-left: auto;
}

.container-zk2{
    max-width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    margin-right: auto;
    margin-left: auto;
}

.border-shadow-in{
    filter: drop-shadow(0 0 0.75rem rgb(225, 223, 223));
}

.fs-zk-65{
    font-size: 65px;
}

.fs-zk-56{
    font-size: 56px;
}

.fs-zk-48{
    font-size: 48px;
}

.fs-zk-40{
    font-size: 40px;
}
.fs-zk-35{
    font-size: 35px;
}

.fs-zk-32{
    font-size: 32px;
}

.fs-zk-30{
    font-size: 30px;
}

.fs-zk-28{
    font-size: 28px;
}

.fs-zk-24{
    font-size: 24px;
}
.fs-zk-22{
    font-size: 22px;
}
.fs-zk-20{
    font-size: 20px;
}

.fs-zk-18{
    font-size: 18px;
}
.fs-zk-17{
    font-size: 17px;
}

.fs-zk-16{
    font-size: 16px;
}

.fs-zk-15{
    font-size: 15px;
}

.fs-zk-14{
    font-size: 14px;
}

.fs-zk-12{
    font-size: 12px;
}

.fs-np-14-mob{
    font-size: 14px;
}

.rounded10{
    border-radius: 10px;
}
.rounded20{
    border-radius: 20px;
}

.rounded30{
    border-radius: 30px;
}

.rounded020{
    border-radius: 0 20px 20px 0;
}

.accordion-button{
    padding: 10px 20px !important;
    background-color: rgb(241, 241, 241);
}
.accordion-item{
    background-color: #f7f7f7;
}

.lsp-2{
    letter-spacing: 2px;
}

.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: #fa61404a;
}
.accordion-button:focus{
    box-shadow: none !important;
}

.card-footer{
    width:30%;
}

.splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Helvetica, 'sans-serif';
    background-color: #f2f3f8;
    color: #5E6278;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
}
  
.splash-screen span {
color: #5E6278;
transition: none !important;
text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
}

.splash-screen img {
margin-left: calc(100vw - 100%);
margin-bottom: 30px;
height: 38px !important;
}
.mx-md-start-sm-end{
    margin-right: auto!important;
    margin-left: auto!important;
}

.w-35 {
    width: 35%!important;
}
.w-40 {
    width: 40%!important;
}

.w-60 {
    width: 60%!important;
}


.w-65 {
    width: 65%!important;
}

.footer-bg{
    background: url('../images/footer.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.border-zk-primary{
    background-color: var(--primary-color);
    border: none;
    color: var(--primary-color);
    height: 2px;
    opacity: 1;
}

.border-zk-white{
    background-color: #fff;
    border: none;
    color: #fff;
    height: 1px;
    opacity: 1;
}
.border-zk-black{
    background-color: #000;
    border: none;
    color: #000;
    height: 1px;
    opacity: 1;
}

.w-50px{
    width: 50px;
}

.w-100px{
    width: 100px;
}
.w-80px{
    width: 80px;
}
.w-150px{
    width: 150px;
}
.footer-logo{
    width:180px;
}
.page-banner-content{
    position: absolute;
    bottom: 11vw;
    width: 90%;
    left: 8.5vw;
}
.blog-page-banner-content{
    position: absolute;
    top: 10vw;
    width: 90%;
    left: 8.5vw;
}
.categories-page-banner-content{
    position: absolute;
    bottom: 7vw;
    width: 100%;
    left: 0vw;
    text-align: center;
}

.categories-page-banner-content .page-banner-title{
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    font-size: 4vw;
    font-weight: 600;
    /* line-height: 1.1; */
}

.blog-page-banner-content{
    top: 12vw;
}

.testDetailLogo{
    width: 200px;
}
.home-social-link{
    color: #fff;
}

.home-page-banner-content{
    position: absolute;
    bottom: 13vw;
    width: 100%;
    left: 6vw;
}

.page-banner-title{
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    font-size: 4vw;
    font-weight: 700;
    /* line-height: 1.1; */
}

.home-page-banner-content2{
    position: absolute;
    bottom: 11vw;
    width: 100%;
    left: 10vw;
}

.page-banner-title2{
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    font-size: 2.8vw;
    font-weight: 700;
    /* line-height: 1.1; */
}
.border-end-header{
    border-right: 2px solid var(--light-gray);
}

.home-sidebar-shop{
    margin-top: -50px;
    position: relative;
    z-index: 1 !important;
}
.home-save-money-bg{
    background-image: url('../images/home/save-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}
.about-page-registration{
    background-image: url('../images/about/about_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}
.about-why-choose-img{
    min-width: 55px !important;
    max-width: 55px;
}
.banner-section {
    position: relative;
    margin-top: 100px;
}

.how-it-works-images{
    width: 70%;

}
.allcategories-card-section{
    background-image: url('../images/categories/categories_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}
.test-item{
    position: relative;
}
.off-label{
    position: absolute;
    top: 0;
    right: 20px;
}
.off-label-img{
    background: url('../images/categories/off.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.all-categories-img{
    width: 250px;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
}
.all-categories-img:hover{
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25)) brightness(1.2);
}
.w-zk-75-100 {
    width: 75%!important;
}

.tp-widget-wrapper{
    text-align: left !important;
}

/* square */

.c-jWYnUm{
    background-color: var(--primary-color) !important;
}

.c-jWYnUm:disabled{
    color: #fff !important;
    background-color: gray !important;
}
  
.sq-card-iframe-container:focus-visible {
    outline: 0 !important;
}

/* Popup style */
#popup .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    z-index: 100000;
    top: 0;
    left: 0;
  }
   
  #popup .box {
    position: relative;
    width: 50%;
    margin: 0 auto;
    height: auto;
    max-height: 75vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 3px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  #popup .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(25% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    color: var(--primary-color);
    font-size: 20px;
  }

.blink {
    animation: blinker 1.5s linear infinite;
    color: #fff;
    font-family: sans-serif;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}
.promo-code-txt{
    font-size: 15px;
}

.promo-code{
    background-color: var(--primary-color);
    font-weight: 600;
    padding: 2px 3px;
}

.blog-date{
    position: absolute;
    top: 0;
    right: 0;
}

.blog-link:hover{
    color: var(--primary-color);
    opacity: 0.9;
    
}

.carousel-control-prev{
    top: 55% ;
    bottom: auto ;
}

.carousel-control-next{
    top: 55% ;
    bottom: auto ;
}

#referrals .page-banner-content{
    position: absolute;
    bottom: 11vw;
    top: 7vw;
    width: 90%;
    left: 8.5vw;
}

.banner-section-referrals {
    position: relative;
    margin-top: 100px;
}

.form-check-input{
    border: 1px solid #a0a7ab !important;
}

.form-check-input:checked{
    background-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
    border: #f05e3e !important;
}

.form-check-input:focus{
    box-shadow: none !important;
}
















  /* ==================================================

                    Media Queries

    ================================================== */

@media screen and (max-width: 1150px){
    #website-nav .container-zk{
        padding-left: 0px;
        padding-right: 0px;
    }
    
    #website-nav .nav-link{
        margin-right: 6px;
    }
    .w-zk-75-100 {
        width: 75%!important;
    }

    .categories-page-banner-content{
        bottom: 5vw;
    }
}

@media screen and (max-width: 991px) {
    .banner-section {
        margin-top: 130px;
    }
    .categories-page-banner-content{
        bottom: 5vw;
    }
    .categories-page-banner-content .page-banner-title{
        font-size: 5vw;
    }
    .blog-img{
        width: 50%;
    }
    .home-sidebar-shop{
        margin-top: -30px;
    }
    .page-banner-content{
        top: 10vw;
        left:0;
        text-align: center;
    }
    .home-page-banner-content{
        top: 7vw;
        left:0;
        text-align: center;
    }
    
    .page-banner-title{
        font-size: 4vw;
        font-weight: 700;
        line-height: 1.1;
    }

    .home-page-banner-content2{
        top: 6vw;
        left: 5.8vw;
        text-align: center;
    }
    
    .page-banner-title2{
        font-size: 3.2vw;
        font-weight: 700;
        line-height: 1.1;
    }

    .logo-img{
        height: 42px !important;
    }
    
    #website-nav{
        padding-top: 0;
    }
    
    #website-nav .container-zk{
        padding-left: 30px;
        padding-right: 30px;
       
    }
    
    #website-responsive .fs-zk-56{
        font-size:38px;
    }

    #website-responsive .fs-zk-52{
        font-size:34px;
    }


    #website-responsive .fs-zk-48{
        font-size:32px;
    }

    #website-responsive .fs-zk-40{
        font-size:28px;
    }

    #website-responsive .fs-zk-35{
        font-size:26px;
    }
    #website-responsive .fs-zk-30{
        font-size:24px;
    }
    #website-responsive .fs-zk-28{
        font-size:22px;
    }

    #website-responsive .fs-zk-26{
        font-size:21px;
    }

    #website-responsive .fs-zk-24{
        font-size:20px;
    }

    #website-responsive .fs-zk-22{
        font-size:19px;
    }

    #website-responsive .fs-zk-20{
        font-size:18px;
    }

    #website-responsive .fs-zk-18{
        font-size:17px;
    }
    #website-responsive .fs-zk-17{
        font-size:16px;
    }

    #website-responsive .fs-zk-16{
        font-size:16px;
    }
    
    .slick-slide-image{
        width:9em;
    }
    
    .container-zk{
        max-width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
    }
    .container-zk2{
        max-width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
    }
    .lsp-2{
        letter-spacing: unset;
    }
    /* .home-sidebar-shop{
        margin-top: -20px;
    } */
    .border-end-header{
        border-right: 1px solid var(--light-gray);
    }
    
}


@media screen and (max-width: 767px) {
    
    #referrals .page-banner-content{
        position: absolute;
        top: 18%;
        width: 100%;
        left: 4vw;
        text-align: left !important;
    }

    .carousel-control-prev{
        top: 65% !important ;
        bottom: auto ;
    }
    
    .carousel-control-next{
        top: 65% !important ;
        bottom: auto ;
    }
    
    .border-md-end-header{
        border-right: 0 !important;
    }
    .home-sidebar-shop{
        margin-top: 20px;
    }
    .footer-logo{
        width:150px;
    }
    .card-footer{
        width:20%;
    }
    .page-banner-content{
        top: 24vw;
    }
    
    .home-page-banner-content{
        top: 12vw;
        left:0;
        text-align: center;
    }
    .page-banner-title{
        font-size: 5.6vw;
        font-weight: 700;
        line-height: 1.1;
    }

    .categories-page-banner-content{
        bottom: 19vw;
    }
    .categories-page-banner-content .page-banner-title{
        font-size: 7vw;
    }
    .logo-img{
        height: 42px !important;
    }
    .page-banner-content .btn-zk-white {
        font-size: 14px;
        margin-top: 2px !important;
    }
    .all-categories-img{
        width: 170px;
    }
    .w-zk-75-100 {
        width: 100%!important;
    }
    #popup .box {
        position: relative;
        width: 90%;
        max-height: 100vh;
        margin-top: calc(100vh - 70vh - 20px);
      }
       
      #popup .close-icon {
        right: calc(10% - 30px);
        top: calc(100vh - 70vh - 33px);
       
      }

      .promo-code-txt{
        font-size: 13px;
    }

}

@media screen and (max-width: 575px) {
    #website-responsive .fs-zk-56{
        font-size:30px;
    }

    #website-responsive .fs-zk-52{
        font-size:28px;
    }

    #website-responsive .fs-zk-48{
        font-size:26px;
    }

    #website-responsive .fs-zk-40{
        font-size:23px;
    }

    #website-responsive .fs-zk-35{
        font-size:21px;
    }
    #website-responsive .fs-zk-30{
        font-size:20px;
    }
    #website-responsive .fs-zk-28{
        font-size:19px;
    }

    #website-responsive .fs-zk-26{
        font-size:19px;
    }

    #website-responsive .fs-zk-24{
        font-size:18px;
    }

    #website-responsive .fs-zk-22{
        font-size:18px;
    }

    #website-responsive .fs-zk-20{
        font-size:17px;
    }

    #website-responsive .fs-zk-18{
        font-size:17px;
    }
    #website-responsive .fs-zk-17{
        font-size:16px;
    }

    #website-responsive .fs-zk-16{
        font-size:16px;
    }

    .all-categories-img{
        width: 140px;
    }
    
}


@media screen and (max-width: 480px) {
    .lh-zk{
        line-height: 1.4;
    }
    .all-categories-img{
        width: 130px;
    }

}

@media screen and (max-width: 400px) {
    

}


@media screen and (max-width: 320px) {

}


@media screen and (max-width: 280px) {

}

@media screen and (max-width: 240px) {

}



